import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { FirestoreService } from './firestore.service';
import * as moment from 'moment';

import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { AuthState, LoginSuccess, SetAuth, SetVerifiedEmail } from '../__states/auth';
import { take, switchMap, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class AuthService {
	apiRootUrl: any = environment.apiRootUrl;

	constructor(
		private Toast: ToastrService,
		private router: Router,
		private http: HttpClient,
		private store: Store,
		private AngularFireAuth: AngularFireAuth,
		private AngularFirestore: AngularFirestore,
		private FirestoreService: FirestoreService) {

	}





	checkEmail(email) {
		return new Promise((resolve, reject) => {
			this.AngularFireAuth.auth.fetchSignInMethodsForEmail(email).then((result) => {
				console.log('Checking')
				if (result.length == 0) {
					resolve('ok');
				} else {
					resolve('exists')
				}
			}).catch(error => {
				reject(false);
			})
		})
	}

	signupAuthentication(profile) {
		return new Promise((resolve, reject) => {
			this.AngularFireAuth.auth.createUserWithEmailAndPassword(profile.email, profile.password).then((result) => {
				profile.age = moment().diff(moment(profile.birthday, 'DD/MM/YYYY'), 'years'),
					delete profile.password;
				delete profile.confirmPassword;
				console.log("signupAuthentication")
				resolve(result);
			}).catch(error => {
				console.error("signupAuthentication")
				switch (error.code) {
					case ('auth/weak-password'):
						reject('The password is too weak.');
						break;
					case ('auth/invalid-email'):
						reject('Invalid email');
						break;
					case ('auth/operation-not-allowed'):
						reject('Not possible to sign up.');
						break;
					case ('auth/email-already-in-use'):
						reject('An account already exists with that email');
						break;
					default:
						reject('Something went wrong');
				}
			})
		})
	}


	signupProfile(profile: any, uid: string) {
		return new Promise((resolve, reject) => {
			this.FirestoreService.set(`profiles/${uid}`, profile).then(() => {
				resolve()
			}).catch(error => {
				reject(error);
			})
		})
	}


	signupVerificationEmail(profile: any, uid: string) {
		return new Promise((resolve, reject) => {
			const url = `${this.apiRootUrl}/v0/signup/send-verification`;
			this.http.post(url, {
				uid: uid,
				email: profile.email
			}).pipe(take(1)).subscribe(data => {
				resolve()
				this.Toast.success(`We have sent you a verification email!`, "For added security we require that you verify your email address");
			})
		})
	}


	signupVerification(uid) {
		return new Promise((resolve, reject) => {
			const url = `${this.apiRootUrl}/v0/signup/verify`;
			this.http.post(url, {
				uid: uid,
			}).subscribe(user => {
				this.store.dispatch(new SetVerifiedEmail(uid));
				console.log(user);
				resolve(true)
			}, error => {
				console.error(error);
				reject(error)
			})	
		})
	}






	loginAuthentication(email, password) {
		return new Promise((resolve, reject) => {
			this.AngularFireAuth.auth.signInWithEmailAndPassword(email, password).then(
					(user: any) => {
						console.log(user);
						this.store.dispatch(new SetAuth(user));
						resolve(user);
					})
				.catch(error => {
					console.log(error);
					reject(error);
				});
		})
	}




	authSignout() {
		this.AngularFireAuth.auth.signOut()
		this.router.navigate(['/login']);
	}

	authUid() {
		return this.AngularFireAuth.auth.currentUser.uid;
	}

}





// loginAuthentication

// loginProfile





// signupSendVerification

// signupVerifyEmail