import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import { timer, Observable, merge,  combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable()
export class FirestoreService {
  constructor(public afs: AngularFirestore) {}

  /// **************
  /// Get a Reference
  /// **************

  db() {
    return firebase.firestore();
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  /// **************
  /// Get Data
  /// **************

  doc$<T>(ref: DocPredicate<T>): Observable<any> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
        if (doc.payload.exists) {
          const data = doc.payload.data() as T;
          data['id'] = doc.payload.id || '';
          return data;
        } else {
          return false;
        }
      }));
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(map(docs => {
        return docs.map(a => a.payload.doc.data()) as T[];
      }));
  } 

  /// with Ids
  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?, subCollection?): Observable<any[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(map(actions => {
        console.log(actions);
        return actions.map(a => {
          const data = a.payload.doc.data() as T;
          const id = a.payload.doc.id;
          return { id, data };
        });
      }));
  }
  colWithIdsAddRemove$<T>(
    ref: CollectionPredicate<T>,
    queryFn?,
    subCollection?
  ): Observable<any[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(map(actions => {
      console.log(ref);
        console.log(actions)
        return actions.map(a => {
          const data = a.payload.doc.data() as T;
          const id = a.payload.doc.id;
          return { id, data };
        });
      }));
  }

  colWithIdsAndSub$<T>(
    ref: CollectionPredicate<T>, queryFn?, subCollection?, subCollectionQuery?): Observable<any[]> {
    return this.colWithIds$(ref, queryFn).pipe(switchMap(colDoc => {
      const res = colDoc.map(a => {
        console.log(a.id)
        console.log(`${ref}/${a.id}/${subCollection}`)
        return this.colWithIds$(`/${ref}/${a.id}/${subCollection}`, subCollectionQuery).pipe(map(
          subColDoc => {
            console.log('subColDoc');
            console.log(subColDoc)
            a.data[subCollection] = subColDoc;
            return a;
          }
        ));
      });
      console.log(res);
      return combineLatest(...res);
    }));
  }


  /// **************
  /// Write Data
  /// **************

  /// Firebase Server Timestamp
  get timestamp() {
    // tslint:disable-next-line:radix
    return parseInt(moment().format('x'));
  }

  makeTimestamp(date) {
    return firebase.firestore.Timestamp.fromDate(date);
  }

  set<T>(ref: DocPredicate<T>, data: any) {
    const timestamp = this.timestamp;
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  update<T>(ref: DocPredicate<T>, data: any) {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timestamp
    });
  }

  delete<T>(ref: DocPredicate<T>) {
    return this.doc(ref).delete();
  }

  add<T>(ref: CollectionPredicate<T>, data) {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  geopoint(lat: number, lng: number) {
    return new firebase.firestore.GeoPoint(lat, lng);
  }

  /// If doc exists update, otherwise set
  upsert<T>(ref: DocPredicate<T>, data: any) {
    const doc = this.doc(ref)
      .snapshotChanges()
      .pipe(take(1))
      .toPromise();

    return doc.then(snap => {
      return snap.payload.exists ? this.update(ref, data) : this.set(ref, data);
    });
  }

  /// **************
  /// Inspect Data
  /// **************

  

  /// **************
  /// Create and read doc references
  /// **************

  /// create a reference between two documents
  connect(host: DocPredicate<any>, key: string, doc: DocPredicate<any>) {
    return this.doc(host).update({
      [key]: this.doc(doc).ref
    });
  }

  /// returns a documents references mapped to AngularFirestoreDocument
  docWithRefs$<T>(ref: DocPredicate<T>) {
    return this.doc$(ref).pipe(map(doc => {
      for (const k of Object.keys(doc)) {
        if (doc[k] instanceof firebase.firestore.DocumentReference) {
          doc[k] = this.doc(doc[k].path);
        }
      }
      return doc;
    }));
  }

  /// **************
  /// Atomic batch example
  /// **************

  /// Just an example, you will need to customize this method.
  orderBatch(collection, deleteReference?) {
    if (deleteReference) {
      console.log('delete');
      firebase
        .firestore()
        .doc(deleteReference)
        .get()
        .then(moveData => {
          const batch = firebase.firestore().batch();
          collection.forEach(collection_item => {
            if (collection_item.create) {
              console.log('Got document to delete and move');
              batch.set(firebase.firestore().doc(collection_item.reference), moveData.data());
              batch.delete(firebase.firestore().doc(collection_item.deleteReference));
            } else {
              batch.update(firebase.firestore().doc(collection_item.reference), {
                order: collection_item.order
              });
            }
          });
          return batch.commit();
        });
    } else {
      console.log('No delete');
      const batch = firebase.firestore().batch();
      collection.forEach(collection_item => {
        batch.update(firebase.firestore().doc(collection_item.reference), {
          order: collection_item.order
        });
      });
      return batch.commit();
    }
  }
}
