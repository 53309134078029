// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  environment: 'development',
  // Firebase Enviroment
  firebaseConfig: {
    apiKey: "AIzaSyDWA3W1BPPRMrDGXNFtTnIjIF1pj4j-at8",
    authDomain: "tirsdagstenniss.firebaseapp.com",
    databaseURL: "https://tirsdagstennis.firebaseio.com",
    projectId: "tirsdagstennis",
    storageBucket: "",
    messagingSenderId: "558037146359",
    appId: "1:558037146359:web:6c742095d3b3c225"
  },
  apiRootUrl: 'https://localhost:2222',
    baseUrl: 'https://localhost:2222'
};
