import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthResolver } from './__resolvers/auth';


// *******************************************************************************
// Routes


const routes: Routes = [{
		path: '',
		loadChildren: './__components/authentication/authentication.module#AuthenticationModule',
		canActivate: [],
	},
	{
		path: 'app',
		loadChildren: './__components/application/application.module#ApplicationModule',
		canActivate: [AuthResolver],
	},	
];

// *******************************************************************************
//

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule {}