import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { AuthState } from '../__states/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirestoreService } from '../__services/firestore.service';

@Injectable()
export class AuthResolver implements CanActivate {
	constructor(private AngularFireAuth: AngularFireAuth, private store: Store, private Router: Router, private ActivatedRoute: ActivatedRoute, public FirestoreService: FirestoreService) {}
	get currentUserObservable(): any {
		return this.AngularFireAuth.authState;
	}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise < boolean > | boolean {
		return new Promise((resolve, reject) => {
			console.log('AuthResolver')
			this.store.select(AuthState).subscribe((auth) => {
				if (auth.initialized) {
					if (auth.user) {
						console.log(auth.user)
						console.log('Logged in')
						resolve(true);
					}
					if (!auth.user) {
						this.Router.navigate(['/login'], );
						resolve(false);
					}
				}
			})
		})
	}

}