import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AuthState } from './__states/auth';
import { Observable } from 'rxjs';
import 'moment/locale/da';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tirsdagstennis';
  constructor(private store: Store) {
  }
  @Select(AuthState.getInitialized) initialized$: Observable<boolean>;
}