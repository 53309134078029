import { Router } from '@angular/router';
import { FirestoreService } from '../__services/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Action, Selector, State, StateContext, Store, NgxsOnInit } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { take, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthStateModel } from './auth';
import { TestBed } from '@angular/core/testing';

export class GetProfiles {
	static type = '[App] Get Profiles';
}
export class GetReserves {
	static type = '[App] Get Reserves';
}
export class GetTirsdage {
	static type = '[App] Get Tirsdage';
}

export class UpdateTirsdag {
	static type = '[App] Update Tirsdag';
	constructor(public data: any, public position: any, public courtId: any, public tirsdagId: any, ansvar?: any) {

	}
}


export class UpdateAReserve {
	static type = '[App] Update A Reserve';
	constructor(public reserveId, public data: any) {

	}
}

export class AddAReserve {
	static type = '[App] Add A Reserve';
	constructor(public reserveId: any, public data: any) {

	}
}

export class DeleteAReserve {
	static type = '[App] Delete A Reserve';
	constructor(public reserveId) {

	}
}

export class UpdateAProfile {
	static type = '[App] Update A Profile';
	constructor(public profileId, public data: any) {

	}
}

export class AddAProfile {
	static type = '[App] Add A Profile';
	constructor(public profileId: any, public data: any) {

	}
}

export class DeleteAProfile {
	static type = '[App] Delete A Profile';
	constructor(public profileId) {

	}
}

export class UpdateBoldAnsvar {
	static type = '[App] Update Bold Ansvar';
	constructor(public ansvar: any, public position: any, public courtId: any, public tirsdagId: any) {

	}
}

export class RemoveDeltager {
	static type = '[App] Remove Deltager';
	constructor(public data: any, public position: any, public courtId: any, public tirsdagId: any) {

	}
}

export class DeleteTirsdag {
	static type = '[App] Delete Tirsdag';
	constructor(public tirsdagId: any) {

	}
}

export interface AppStateModel {
	profiles ? : any;
	tirsdage ? : any;
	profile ? : any;
	reserves?:  any;
	test ? : any;
}

@State < AppStateModel > ({
	name: 'app',
	defaults: {
		profiles: null,
		reserves: null,
		tirsdage: null,
		test: null,
		profile: null
	}
})
export class AppState implements NgxsOnInit {
	constructor(
		private store: Store,
		private afAuth: AngularFireAuth,
		private FirestoreService: FirestoreService,
		private http: HttpClient) {}



	@Selector()
	static Tirsdage(state: AppStateModel) {
		return state.tirsdage;
	}

	/**
	 * Dispatch CheckSession on start
	 */
	ngxsOnInit(ctx: StateContext < AppStateModel > ) {
		ctx.dispatch(new GetTirsdage());
		ctx.dispatch(new GetProfiles());
		ctx.dispatch(new GetReserves());
	}

	@Action(GetTirsdage)
	GetTirsdage(ctx: StateContext < AppStateModel > ) {
		this.FirestoreService.colWithIds$('tirsdage', ref => ref.orderBy('dato')).subscribe(data => {
			this.FirestoreService.doc$('tirsdage/l5Fmr0xxlPR2GQS55V1G/deltagere/0bSzITi1C9fT0J9xWjzQ').subscribe(data2 => {
				console.log('data2')
				console.log(data)
				console.log(data2)
				ctx.patchState({
					tirsdage: data
				});
			})
		})
	}

	@Action(GetProfiles)
	GetProfiles(ctx: StateContext < AppStateModel > ) {
		this.FirestoreService.colWithIds$('profiles').subscribe((data) => {
			ctx.patchState({
				profiles: data
			});
		})
	}

	@Action(GetReserves)
	GetReserves(ctx: StateContext < AppStateModel > ) {
		this.FirestoreService.colWithIds$('reserves').subscribe((data) => {
			ctx.patchState({
				reserves: data
			});
		})
	}

	@Action(UpdateTirsdag)
	onUpdateTirsdag(ctx: StateContext < AppStateModel > , { data, position, courtId, tirsdagId }) {
		const state = ctx.getState();
		const index = ctx.getState().tirsdage.findIndex(item => item.id == tirsdagId);
		ctx.getState().tirsdage[index].data.courts[courtId][position] = {
			name: data,
			active: true
		};
		console.log(state.tirsdage[index]);
		this.FirestoreService.update(`tirsdage/${tirsdagId}`, state.tirsdage[index].data).then((data) => {
			ctx.patchState({
				tirsdage: state.tirsdage
			});
		})
	}

	@Action(UpdateAProfile)
	onUpdateAProfile(ctx: StateContext < AppStateModel > , { data, profileId }) {
		const state = ctx.getState();
		const index = ctx.getState().profiles.findIndex(item => item.id == profileId);
		console.log(state.profiles[index]);
		this.FirestoreService.update(`profiles/${profileId}`, data).then((data) => {
		})
	}

	@Action(AddAProfile)
	onAddAProfile(ctx: StateContext < AppStateModel > , { profileId, data }) {
		const state = ctx.getState();
		this.FirestoreService.upsert(`profiles/${profileId}`, data).then((data) => {
		})
	}


	@Action(DeleteAProfile)
	onDeleteAProfile(ctx: StateContext < AppStateModel > , { profileId }) {
		const state = ctx.getState();
		this.FirestoreService.delete(`profiles/${profileId}`).then((data) => {
		})
	}

	@Action(UpdateAReserve)
	onUpdateAReserve(ctx: StateContext < AppStateModel > , { data, reserveId }) {
		const state = ctx.getState();
		const index = ctx.getState().profiles.findIndex(item => item.id == reserveId);
		console.log(state.profiles[index]);
		this.FirestoreService.update(`reserves/${reserveId}`, data).then((data) => {
		})
	}

	@Action(AddAReserve)
	onAddAReserve(ctx: StateContext < AppStateModel > , { reserveId, data }) {
		const state = ctx.getState();
		this.FirestoreService.upsert(`reserves/${reserveId}`, data).then((data) => {
		})
	}


	@Action(DeleteAReserve)
	onDeleteAReserve(ctx: StateContext < AppStateModel > , { reserveId }) {
		const state = ctx.getState();
		this.FirestoreService.delete(`reserves/${reserveId}`).then((data) => {
		})
	}

	@Action(UpdateBoldAnsvar)
	onUpdateBoldAnsvar(ctx: StateContext < AppStateModel > , { ansvar, position, courtId, tirsdagId }) {
		const state = ctx.getState();
		const index = ctx.getState().tirsdage.findIndex(item => item.id == tirsdagId);
		ctx.getState().tirsdage[index].data.courts[courtId][position] = {
			...ctx.getState().tirsdage[index].data.courts[courtId][position],
			ansvar: (ansvar ? true : false)
		};
		console.log(state.tirsdage[index]);
		this.FirestoreService.update(`tirsdage/${tirsdagId}`, state.tirsdage[index].data).then((data) => {
			ctx.patchState({
				tirsdage: state.tirsdage
			});
		})
	}

	@Action(RemoveDeltager)
	onRemoveDeltager(ctx: StateContext < AppStateModel > , { data, position, courtId, tirsdagId }) {
		const state = ctx.getState();
		const index = ctx.getState().tirsdage.findIndex(item => item.id == tirsdagId);
		ctx.getState().tirsdage[index].data.courts[courtId][position] = {
			name: "",
			active: false
		};
		console.log(state.tirsdage[index]);
		this.FirestoreService.update(`tirsdage/${tirsdagId}`, state.tirsdage[index].data).then((data) => {
			ctx.patchState({
				tirsdage: state.tirsdage
			});
		})
	}

	@Action(DeleteTirsdag)
	onDeleteTirsdag(ctx: StateContext < AppStateModel > , { tirsdagId }) {
		const state = ctx.getState();
		const index = ctx.getState().tirsdage.findIndex(item => item.id == tirsdagId);
		console.log(state.tirsdage[index]);
		this.FirestoreService.delete(`tirsdage/${tirsdagId}`).then((data) => {
			ctx.patchState({
				tirsdage: state.tirsdage.filter(a => a.id != tirsdagId)
			});
		})
	}




}