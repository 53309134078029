import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { FirebaseModule } from './__modules/firebase';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { HttpClientModule } from '@angular/common/http';
import { DatepickerModule, BsDatepickerModule, } from 'ngx-bootstrap/datepicker';
import { RouterModule } from '@angular/router';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { SlugifyPipe, NgPipesModule} from 'ngx-pipes';
import { ReferencePipe } from './__pipes/reference.pipe';
import { MomentModule } from 'ngx-moment';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';



@NgModule({
	declarations: [
		ReferencePipe
	],
	providers: [
		SlugifyPipe,
		
	],
	imports: [
		CommonModule,
		RouterModule,
		MomentModule,
		HttpClientModule,
		NgPipesModule,
		NgxsModule,
		BsDropdownModule.forRoot(),
		AccordionModule.forRoot(),
		ModalModule.forRoot(),
		CollapseModule.forRoot(),
		DatepickerModule.forRoot(),
		TooltipModule.forRoot(),
		BsDatepickerModule.forRoot(),
		TabsModule.forRoot(),
		TimepickerModule.forRoot(),

		NgxFullCalendarModule,
		ReactiveFormsModule,
		FormsModule,
		FirebaseModule,

	],
	entryComponents: [
	],
	exports: [
		CommonModule,
		ReferencePipe,
		SlugifyPipe,
		RouterModule,
		MomentModule,
		NgxFullCalendarModule,
		NgPipesModule,
		NgxsModule,
		BsDropdownModule,
		TimepickerModule,
		AccordionModule,
		CollapseModule,
		TooltipModule,
		DatepickerModule,
		BsDatepickerModule,
		TabsModule,
		ModalModule,
		ReactiveFormsModule,

		FormsModule,
		

	]
})
export class SharedModule {}