import { Router } from '@angular/router';
import { FirestoreService } from '../__services/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Action, Selector, State, StateContext, Store, NgxsOnInit } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { take, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export class CheckSession {
  static type = '[Auth] CheckSession';
}
export class LoginWithGoogle {
  static type = '[Auth] LoginWithGoogle';
}
export class LoginWithFacebook {
  static type = '[Auth] LoginWithFacebook';
}

export class UnsetAuth {
  static type = '[Auth] UnsetAuth';
}
export class LogoutSuccess {
  static type = '[Auth] LogoutSuccess';
}

export class LoginRedirect {
  static type = '[Auth] LoginRedirect';
}

export class SetAuth {
  static type = '[Auth] Set Authentication';
  constructor(public user: any) {}
}

export class SetVerifiedEmail {
  static type = '[Auth] Set Emailed Verified';
  constructor(public user: any) {}
}

export class SignupError {
  static type = '[Auth] SignupError';
  constructor(public error: any) {}
}

export class AlreadyLoggedIn {
  static type = '[Auth] AlreadyLoggedIn';
  constructor(public user: any) {}
}

export class LoginSuccessRedirect {
  static type = '[Auth] LoginSuccessRedirect';
}

export class VerifyEmail {
  static type = '[Auth] Verify Email';
  constructor() {}
}

export class LoginSuccess {
  static type = '[Auth] LoginSuccess';
  constructor(public user: any) {}
}
export class LoginFailed {
  static type = '[Auth] LoginFailed';
  constructor(public error: any) {}
}

export interface AuthStateModel {
  initialized: boolean;
  user?: any;
  uid: any;
  profile: any;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    initialized: false,
    uid: null,
    user: null,
    profile: null
  }
})
export class AuthState implements NgxsOnInit {
  constructor(
    private store: Store,
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    private FirestoreService: FirestoreService,
  ) {}
  /**
   * Selectors
   */
  @Selector()
  static getInitialized(state: AuthStateModel): boolean {
    return state.initialized;
  }

  @Selector()
  static getUser(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static getUid(state: AuthStateModel) {
    return state.uid;
  }

  /**
   * Dispatch CheckSession on start
   */
  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new CheckSession());
  }

  @Action(CheckSession)
  checkSession(ctx: StateContext<AuthStateModel>) {
    return this.afAuth.authState.pipe(
      take(1),
      tap((user: any) => {
        if (user) {
          console.log(`CheckSession: ${user.email} is logged in`);
          ctx.dispatch(new AlreadyLoggedIn(user));
          return;
        } else {
          ctx.patchState({ initialized: true });
        }
        console.log('CheckSession: no user found');
      })
    );
  }

  @Action(UnsetAuth)
  onUnsetAuth(ctx: StateContext<AuthStateModel>) {
    return this.afAuth.auth.signOut().then(() => {
      ctx.patchState({
        uid: null,
        user: null
      });
    });
  }

  @Action(SetAuth)
  onSetAuth(ctx: StateContext<AuthStateModel>, { user }) {
    this.FirestoreService.doc$(`profiles/${user.user.uid}`).pipe().subscribe(profile => {
    ctx.patchState({
      initialized: true,
      user: user.user,
      profile: profile.data,
      uid: user.user.uid
    });
  })
  }

  @Action(SetVerifiedEmail)
  onSetVerifiedEmail(ctx: StateContext<AuthStateModel>, { user }) {
    ctx.patchState({
      user: {
        emailVerified: true
      }
    });
  }

  // Login
  @Action(AlreadyLoggedIn)
  onAlreadyLoggedIn(ctx: StateContext<AuthStateModel>, event: LoginSuccess) {
    this.FirestoreService.doc$(`profiles/${event.user.uid}`).pipe().subscribe(profile => {
    ctx.patchState({
      initialized: true,
      user: event.user,
      profile: profile,
      uid: event.user.uid
    });
  })
  }

  @Action(LoginSuccessRedirect)
  onLoginSuccessRedirect(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new Navigate(['/account/decks']));
  }

  @Action(LoginRedirect)
  onLoginRedirect(ctx: StateContext<AuthStateModel>) {
    console.log('onLoginRedirect, navigating to login');
    ctx.dispatch(new Navigate(['/']));
  }

  @Action(LoginSuccess)
  onLoginSuccess(ctx: StateContext<AuthStateModel>, event: LoginSuccess) {
    ctx.patchState({
      user: event.user.user,
      uid: event.user.uid
    });
    ctx.dispatch(new LoginSuccessRedirect());
  }

  @Action(LoginFailed)
  onLoginError(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      user: undefined
    });
  }
}
