import { Title, BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FirestoreService, AuthService } from './__services';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { AuthResolver } from './__resolvers/auth';


// States
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AuthenticationModule } from './__components/authentication';
import { AuthState } from './__states/auth';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterStateSnapshot, Params, RouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router';
import { SharedModule } from './app.shared.module';
import { ApplicationModule } from './__components/application/application.module';
import { AppState } from './__states/app.state';

import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';

export interface RouterStateParams {
  url: string;
  params: Params;
  queryParams?: Params;
}


export class AARouteReuseStrategy extends RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(route: ActivatedRouteSnapshot, handle: {}): void {

  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): {} {
     return null;
 }
 shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
   return false; // default is true if configuration of current and future route are the same
 }
}

 
// Map the router snapshot to { url, params, queryParams }
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): any {
    let route = routerState.root;
    console.log(routerState);
    let path = routerState.root.firstChild.routeConfig.path;
    const params = {};
    while (route.firstChild) {
      if (route.firstChild.params) {
        const result = Object.keys(route.firstChild.params).reduce(function(r, k) {
          return r.concat(k, route.firstChild.params[k]);
        }, []);
        params[result[0]] = result[1];
      }
      route = route.firstChild;
    }
    const {
      url,
      root: { queryParams }
    } = routerState;

    return { url, params, queryParams, path };
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
  
    HttpClientModule,
    NgxsModule.forRoot([AuthState, AppState]),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    
    // App

    AppRoutingModule,

    // External

    ToastrModule.forRoot({
      timeOut: 8000,
      newestOnTop: true,
      maxOpened: 1,
      autoDismiss: true,
      disableTimeOut: false,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true
    }),
  ],

  entryComponents: [], // add!

  providers: [{ provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },   {
    provide: RouteReuseStrategy,
    useClass: AARouteReuseStrategy
  }, Title, FirestoreService, AuthService, AuthResolver],

  bootstrap: [AppComponent]
})
export class AppModule {}
